import * as React from "react";

const FileEarmarkFillKBC: React.FC<{}> = () => (
  <svg xmlns="https://www.w3.org/2000/svg" width="1em" height="1em"
       viewBox="0 0 48 48">
    <g id="Layer_2" data-name="Layer 2">
      <text transform="translate(-1.6 -1949.3)" font-size="16" fill="#caffc5" font-family="Museo-500, Museo"
            font-weight="500">
        <tspan letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="0" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="0" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="0">eady: social-exp</tspan>
        <tspan x="221.9" y="0" letter-spacing="-0.02em">r</tspan>
        <tspan x="227.8" y="0">-person</tspan>
        <tspan x="0" y="300" letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="300" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="300" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="300">eady: in</tspan>
        <tspan x="160" y="300" letter-spacing="-0.02em">f</tspan>
        <tspan x="164.8" y="300">o</tspan>
        <tspan x="174.7" y="300" letter-spacing="0.01em">r</tspan>
        <tspan x="181" y="300">mation-exp</tspan>
        <tspan x="267.9" y="300" letter-spacing="-0.02em">r</tspan>
        <tspan x="273.8" y="300">-con</tspan>
        <tspan x="309.1" y="300" letter-spacing="-0.01em">t</tspan>
        <tspan x="314.6" y="300">act</tspan>
        <tspan x="0" y="1200" letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="1200" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="1200" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="1200">eady: communication-exp</tspan>
        <tspan x="298.7" y="1200" letter-spacing="-0.02em">r</tspan>
        <tspan x="304.6" y="1200">-gl</tspan>
        <tspan x="325.3" y="1200" letter-spacing="-0.01em">o</tspan>
        <tspan x="335" y="1200">be</tspan>
        <tspan x="0" y="1260" letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="1260" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="1260" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="1260">eady: communication-exp</tspan>
        <tspan x="298.7" y="1260" letter-spacing="-0.02em">r</tspan>
        <tspan x="304.6" y="1260">-gl</tspan>
        <tspan x="325.3" y="1260" letter-spacing="-0.01em">o</tspan>
        <tspan x="335" y="1260">be</tspan>
        <tspan x="0" y="1320" letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="1320" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="1320" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="1320">eady: communication-exp</tspan>
        <tspan x="298.7" y="1320" letter-spacing="-0.02em">r</tspan>
        <tspan x="304.6" y="1320">-gl</tspan>
        <tspan x="325.3" y="1320" letter-spacing="-0.01em">o</tspan>
        <tspan x="335" y="1320">be</tspan>
        <tspan x="0" y="1380" letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="1380" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="1380" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="1380">eady: communication-exp</tspan>
        <tspan x="298.7" y="1380" letter-spacing="-0.02em">r</tspan>
        <tspan x="304.6" y="1380">-gl</tspan>
        <tspan x="325.3" y="1380" letter-spacing="-0.01em">o</tspan>
        <tspan x="335" y="1380">be</tspan>
        <tspan x="0" y="1440" letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="1440" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="1440" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="1440">eady: communication-exp</tspan>
        <tspan x="298.7" y="1440" letter-spacing="-0.02em">r</tspan>
        <tspan x="304.6" y="1440">-gl</tspan>
        <tspan x="325.3" y="1440" letter-spacing="-0.01em">o</tspan>
        <tspan x="335" y="1440">be</tspan>
        <tspan x="0" y="1500" letter-spacing="-0.02em">2</tspan>
        <tspan x="8.8" y="1500" letter-spacing="0em">4px &amp; 48px</tspan>
        <tspan x="94.7" y="1500" letter-spacing="-0.02em">r</tspan>
        <tspan x="100.6" y="1500">eady: in</tspan>
        <tspan x="160" y="1500" letter-spacing="-0.02em">f</tspan>
        <tspan x="164.8" y="1500">o</tspan>
        <tspan x="174.7" y="1500" letter-spacing="0.01em">r</tspan>
        <tspan x="181" y="1500">mation-exp</tspan>
        <tspan x="267.9" y="1500" letter-spacing="-0.02em">r</tspan>
        <tspan x="273.8" y="1500">-con</tspan>
        <tspan x="309.1" y="1500" letter-spacing="-0.01em">t</tspan>
        <tspan x="314.6" y="1500">ac</tspan>
        <tspan x="331.9" y="1500" letter-spacing="-0.01em">t</tspan>
        <tspan x="337.4" y="1500">bo</tspan>
        <tspan x="356.9" y="1500" letter-spacing="-0.01em">o</tspan>
        <tspan x="366.6" y="1500">k</tspan>
      </text>
    </g>
    <g id="Layer_1" data-name="Layer 1">
      <path d="M29.2,5V15.4H39.5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1.6"/>
      <path d="M29.2,5H8.5V43h31V15.4Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1.6"/>
      <path d="M15.2,22.1H32.4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1.6"/>
      <path d="M15.2,35.4H32.4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1.6"/>
      <path d="M15.2,13.4h6.9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1.6"/>
      <path d="M15.2,28.7H32.4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1.6"/>
    </g>
  </svg>
);

export default FileEarmarkFillKBC;
