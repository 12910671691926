import * as React from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import {
  Datum,
  FilePreview,
  PersonInvolved,
  Question,
  SchadepostenItem,
  Section,
  Summary,
  SummaryRequest,
  SummaryResponse,
  TranslationKey
} from '../types';
import SummaryFile from './SummaryFile';
import SummaryRow from './SummaryRow';
import { formatDate } from "../utils/dateParse";

declare global {
  interface Window {
    translator: any;
  }
}

type Props = SummaryRequest;


const summaryAPI = '/claimant-api/summary/claim/';

const NumberedSection: React.FC<{ index: number }> = ({ index, children }) => (
  <div className="numbered-section d-flex flex-row mb-3">
    <div className="number">
      {index + 1}
    </div>
    <div className="flex-grow-1">
      {children}
    </div>
  </div>
);

const ClaimSummary: React.FC<Props> = ({ schadegevalId, showMeta }) => {
  const [summary, setSummary] = React.useState<Summary | undefined>(undefined);
  const [error, setError] = React.useState<string | null>(null);

  const t = (title: TranslationKey) => {
    return window.translator.t([`dossierlabel_${title}`, `vraaggroep_${title}`, title], title);
  }

  const joinCheckboxValues = (answer: string | string[]): string => {
    if (typeof answer === 'string') {
      return window.translator.t(answer);
    }

    return answer.map(choice => window.translator.t(choice)).join(', ');
  };

  const transformResponse = (response: SummaryResponse): Summary => {
    const { datum_en_tijd_aanmaak, schadedatum, ...rest } = response;
    return ({
      ...rest,
      datum_en_tijd_aanmaak: `${formatDate(datum_en_tijd_aanmaak, true)} ${datum_en_tijd_aanmaak.timezone}`,
      schadedatum: formatDate(schadedatum)
    });
  };

  const renderMeta = (summary: Summary) => {
    const orderedMeta: Array<keyof Summary> = [
      "schadegeval_id",
      "datum_en_tijd_aanmaak",
      "schadedatum",
      "polisbestand",
      "gebeurtenis",
      "gedelegeerde_id"
    ];

    return orderedMeta.map(label => (
      <SummaryRow
        key={label}
        label={t(label)}
        value={window.translator.t(summary[label], summary[label])}
      />
    ));
  };

  const renderContent = (summary: Summary) => {
    const {
      contentPolisblad,
      contentQuestions,
      contentSchadeposten,
      contentThirdParty,
      contentWitness,
      contentFiles,
      contentInjured,
      contentDriver,
      contentClaim,
      contentRepairOrder,
      policyTypeName,
      ...rest
    } = summary;
    const filteredQuestions = contentQuestions.filter(question => question.title !== 'overview_exclusions_question');

    let restObject = Object.values(rest).filter(val => (val !== null && val !== 0 && val !== undefined));

    return (
      <>
        {filteredQuestions.map(renderQuestion)}
        {contentFiles && renderSection(contentFiles, renderFile)}
        {contentSchadeposten && renderSection(contentSchadeposten, renderSchadepostenItem)}
        {restObject.map(val => renderSection(val, renderPerson))}
      </>
    );
  };

  const renderSection = <T extends object>(
    section: Section<T>,
    renderChildren: (child: T, index: number) => JSX.Element
  ) => {
    const { kind, title, children } = section;

    if (
      kind !== "section"
      || title === undefined
      || children === undefined
      || ["payment"].includes(title)
    ) {
      return null;
    }

    return (
      <div className={'section_' + title} key={title}>
        <h3 className="section-title">{t(title)}</h3>
        {children.map(renderChildren)}
      </div>
    );
  };

  const renderSchadepostenItem = (item: SchadepostenItem, index: number) => {
    const {
      aanschafkosten,
      aantal_m,
      claim_id,
      herstelopdracht_id,
      id,
      toegekende_schadebedrag,
      status_voor_systeem,
      schadebedrag_conform_expert,
      schadebedrag_conform_systeem,
      schadecategorie,
      schadepost_id,
      ses_suggestion,
      subcategorie,
      leeftijd,
      ...rest
    } = item;

    return (
      <NumberedSection index={index} key={index}>
        {schadecategorie &&
        <SummaryRow
            label={t('schadecategorie')}
            value={[window.translator.t(schadecategorie), window.translator.t(subcategorie)]
              .filter(e => e)
              .join(' > ')}
        />
        }
        {leeftijd &&
        <SummaryRow
          key={'leeftijd'}
          label={t('leeftijd')}
          value={formatDate(leeftijd)}
        />
        }
        {Object.entries(rest)
          .map(([label, value]) => {
            if (
              value === ""
              || value === null
              || value === 0
              || !["string", "number"].includes(typeof value)
            ) {
              return null;
            }

            return (
              <SummaryRow
                key={label}
                label={t(label)}
                value={window.translator.t(value.toString())}
              />
            );
          })}
        {aanschafkosten && parseFloat(aanschafkosten.toString()) > 0 &&
        <SummaryRow
            label={t('aanschafkosten')}
            value={window.translator.t(aanschafkosten.toString())}
            formatting='currency'
        />
        }
      </NumberedSection>
    );
  }

  const renderPerson = (person: PersonInvolved, index: number) => {
    const {
      firstName,
      lastName,
      type,
      email,
      mobile,
      street,
      huisnummer,
      postcode,
      plaats,
      ...rest
    } = person;
    const addressLine1 = street ? [street, huisnummer].join(' ') : undefined;
    const addressLine2 = (plaats || postcode) ? [plaats, postcode].join(' ') : undefined;
    const personFields = {
      naam: [firstName, lastName].filter(e => e).join(' '),
      type,
      email,
      mobiel: mobile,
      adres: [addressLine1, addressLine2].filter(Boolean).join(', '),
      ...rest
    };

    return (
      <NumberedSection index={index} key={index}>
        {Object.entries(personFields)
          .map(([label, value]) => {
            if (typeof value === "object") {
              value = formatDate(value as Datum);
            }

            if (value === "" || value === null || value === undefined || !["string", "number"].includes(typeof value)) {
              return null;
            }

            return (
              <SummaryRow
                key={label}
                label={t(label)}
                value={window.translator.t(value)}
              />
            );
          })}
      </NumberedSection>
    );
  };

  const renderFile = (file: FilePreview) => {
    const { originalName, technicalName, folderType, uploadType } = file;
    const fileUrl = `/api/file_handler.php?file=${technicalName}&folder_type=${folderType}`;

    return (
      <SummaryFile
        key={technicalName}
        technicalName={technicalName}
        meta={t(uploadType)}
        title={originalName}
        url={fileUrl}
      />
    );
  };

  const renderQuestion = (question: Question) => {
    const { title, answer, inputType } = question;

    if (title === '' || answer === '' || answer === null) {
      return null;
    }

    switch (inputType) {
      case 'date':
        return (
          <SummaryRow
            key={title}
            label={t(title)}
            value={formatDate(answer)}
          />
        );
      case 'choice':
        return (
          <SummaryRow
            key={title}
            label={t(title)}
            value={window.translator.t(answer)}
          />
        );
      case 'text':
        return (
          <SummaryRow
            key={title}
            label={t(title)}
            value={answer as string}
          />
        );
      case 'polar':
        return (
          <SummaryRow
            key={title}
            label={t(title)}
            formatting='boolean'
            value={answer as string}
          />
        );
      case 'checkbox':
        return (
          <SummaryRow
            key={title}
            label={t(title)}
            value={joinCheckboxValues(answer)}
          />
        )
      default:
        return null;
    }
  };

  React.useEffect(() => {
    axios.get<any>(summaryAPI + schadegevalId)
      .then(({ data }) => {
        if (!data || typeof data !== "object" || data.length === 0) {
          setError(window.translator.t('geen_informatie_gevonden'));
        } else {
          setSummary(transformResponse(data));
        }
      })
      .catch(response => {
        response.message && setError(response.message);
      });
  }, [schadegevalId]);

  if (!summary) {
    return error && <p>error</p>;
  }

  return (
    <Container id="claimSummary" className="dense-text">
      {showMeta && renderMeta(summary)}
      {renderContent(summary)}
    </Container>
  );
};

export default ClaimSummary;
