import "core-js";
import "regenerator-runtime/runtime";
import "./utils/polyfills";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ClaimSummary from './components/ClaimSummary';
import ErrorBoundary from "./components/ErrorBoundary";


const summaryContainer = document.getElementById('js-summary-container');
if (summaryContainer) {
  const schadegevalId = parseInt(summaryContainer.getAttribute("data-id"));

  ReactDOM.render(
    <ErrorBoundary>
      <div className="mb-3">
        <ClaimSummary
          schadegevalId={schadegevalId}
          showMeta={true}
        />
      </div>
    </ErrorBoundary>
    ,
    summaryContainer
  );
}
