import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { formatDate } from '../utils/dateParse';

interface SummaryRowProps {
  label: string;
  value: string | number | null;
  formatting?: 'decimal' | 'currency' | 'boolean' | 'date' | 'uppercase'
}

const SummaryRow: React.FC<SummaryRowProps> = ({ label, value, formatting }) => {
  let formattedValue = null;

  if (value === null || value === undefined || value === '') {
    return null;
  }

  if (formatting) {
    switch (formatting) {
      case 'decimal':
        formattedValue =
          <NumberFormat thousandSeparator={'.'}
                        displayType={'text'}
                        isNumericString={true}
                        decimalSeparator={','}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandsGroupStyle={'thousand'}
                        value={value}/>
        break;
      case 'currency':
        formattedValue =
          <NumberFormat thousandSeparator={'.'}
                        displayType={'text'}
                        isNumericString={true}
                        decimalSeparator={','}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandsGroupStyle={'thousand'}
                        prefix={'€ '}
                        value={value}/>
        break;
      case 'boolean':
        formattedValue = (value === 'true' || value === 'ja') ? 'Ja' : 'Nee';
        break;
      case 'date':
        formattedValue = formatDate(value, true);
        break;
      case 'uppercase':
        formattedValue = value.toString().toUpperCase();
    }
  }

  return (
    <Row className='mb-2'>
      <Col xs={12} sm={4}>
        <span className='text-label'>{label}</span>
      </Col>
      <Col xs={12} sm={8}>
        <span className='text-muted' id={label}>{formatting ? formattedValue : value}</span>
      </Col>
    </Row>
  )
};

export default SummaryRow;
