import { DateTime } from "luxon";

export const formatDate = (datum: any, hasTime = false) => {
  try {
    let date = datum;

    if (typeof datum === "object") {
      date = datum.date;
    }

    const parsedDate = DateTime.fromSQL(date.split('.')[0]);

    if (hasTime) {
      return parsedDate.toFormat('dd-MM-yyyy HH:mm:ss');
    }

    return parsedDate.toFormat('dd-MM-yyyy');
  } catch {
    return '';
  }
};
