import * as React from 'react';
import { Image } from 'react-bootstrap';
import FileEarmarkFillKBC from '../icons/FileEarmarkFill_KBC';

interface SummaryFileProps {
  technicalName: string;
  meta: string;
  title: string;
  url: string;
}

const SummaryFile: React.FC<SummaryFileProps> = ({ technicalName, meta, title, url}) => {
  const fileIsImage = technicalName.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  const previewSize = 80;

  return (
    <div className="d-flex mb-2">
      <div className="flex-shrink-0">
      <a href={url} target="_blank">
        {fileIsImage ? (
          <Image
            src={url}
            alt={title}
            width={previewSize}
            thumbnail={true}
          />
          ) : (
          <div
            className="text-muted"
            style={{
              fontSize: previewSize,
              lineHeight: '0',
              width: previewSize
            }}
          >
            <FileEarmarkFillKBC />
          </div>
          )
        }
      </a>
      </div>
      <div className="ml-3 break-word">
        <span className="text-black">{title}</span><br/>
        <span className="text-muted">{meta}</span>
      </div>
    </div>
  );
};

export default SummaryFile;
